import React, {useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import AppDataContext from '../../context/AppDataContext';

import HeroImage from '../common/HeroImage';
import Quote from '../common/Quote';
import Text from '../Text';
import ImageCTA from '../common/ImageCTA';

import { checkImage } from '../../utils/Utils';

const TourOverviewPage = () => {
  const {buildingId} = useParams();
  const {selectedLanguage, loadedAppData, setCurPage, getLocalizedString} = useContext(AppDataContext);

  /**
   * -The overview page for the Guided Tour
   * -Note that the header has a solid black background
   * -Display a hero image
   * -Display the description of the theme for the level
   * -Display a list of artwork found on this level
   * -Note that the layout and functionality of this page is identical to the LevelOverviewPage, probably should combine these 2 components and differentiate them with a prop
   */
  useEffect(()=>{
    const page = {
      type: 1,
      label: 'Tour Overview'
    };

    setCurPage(page);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const buildPage = () => {
    const data = loadedAppData.current['tourOverviewPage'].getJsonContent(selectedLanguage);

    //Get a reference to the tours data set
    const tourData = loadedAppData.current['tours'].getJsonContent(selectedLanguage);
    return(
      <>
        <HeroImage imageUrl={checkImage(data.acf.hero_image)} altText='Collection Hero Image' shouldAnimate={true} />
        <div className='tour-overview-container'>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='level-title tag bronze-tag'>{getLocalizedString('label_tour')}</motion.div>
          {
          // Loop through and build out the different component types
            data.acf.content_sections.map((section, index) => {
              switch(section.acf_fc_layout){
                case 'text':
                  return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} />;
                case 'quote':
                  return <Quote key={section.acf_fc_layout + '-' + index} quote={section.quote} name={section.quotee} />;
                case 'message':
                  return <p key={section.acf_fc_layout + '-' + index}>Insert Message component here</p>;
              }
            })
          }
        </div>
        <div className='tours-container'>
          {
            (tourData) && tourData.map( (tour) => {
              return(
                <ImageCTA
                  key={tour.slug}
                  link={'/' + buildingId + '/guided-tour/' + tour.slug}
                  tag={getLocalizedString('label_tour')}
                  title={tour.title}
                  ctaLinkLabel={tour.locations.length + ' ' + getLocalizedString('label_art_locations')}
                  imageUrl={checkImage(tour.imageUrl)}
                />
              );
            })
          }
        </div>
        <div className='bottom-line'></div>
      </>
    );
  };

  return(
    <div className='tour-overview-page'>
      {buildPage()}
    </div>
  );
};

export default TourOverviewPage;