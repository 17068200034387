import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppDataContext from '../../context/AppDataContext';

const MainBody = ({ children }) => {
  /** 
  * This component is just a wrapper for the different pages
  */
  const { buildingId } = useParams();
  const { hasDataLoaded, loadAppData } = useContext(AppDataContext);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Check to see if data is loaded in case the user refreshes or navigates to the page directly
    if (!hasDataLoaded) {
      //If data hasn't loaded, load it now
      loadAppData(buildingId);
    }
  }, []);

  // Wait until data is loaded and set the local page state to render
  useEffect(() => {
    if (hasDataLoaded) {
      setIsLoaded(true);
    }
  }, [hasDataLoaded]);

  return (
    <section className='main-body'>
      {(isLoaded) ? children : <p>Loading</p>}
    </section>
  );
};

MainBody.propTypes = {
  children: PropTypes.element.isRequired
};

export default MainBody;