import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AppDataContext from '../../context/AppDataContext';

import logo from '../../assets/images/telus-logo.svg';

const BuildingSelectPage = ({list}) => {
  /**
   * -Essentially the homepage of the app
   * -Display greeting text with a building select
   * -Once user selects a building for the list the "Continue" button appears
   * -Click continue to navigate to the next "Collection" page
   */
  const navigate = useNavigate();

  const {selectedLanguage, setSelectedLanguage, hasDataLoaded, loadAppData, getLocalizedString} = useContext(AppDataContext);

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    //Check the length of the building list
    //TODO: length check should be 1. It's set to 0 for testing purposes so that the building select screen appears at launch
    if(list.length === 0){
      const path = list[0].path;

      //Automatically navigate to the collection page
      navigate(path + 'collection');
    }
  }, []);

  useEffect( () => {
    if(hasDataLoaded){
      navigate(selectedBuilding + '/collection');
    }
  }, [hasDataLoaded]);

  const handleLanguageClick = () => {
    if(selectedLanguage === 'en'){
      setSelectedLanguage('fr');
    }else{
      setSelectedLanguage('en');
    }
  };

  const handleOnSelectChange = (e) => {
    const val = e.currentTarget.value.replace(/\//g, '');
    setSelectedBuilding(val);
  };

  const handleOnContinueClick = () => {
    if(!isLoading){
      loadAppData(selectedBuilding);
      setIsLoading(true);
    }
  };

  const checkButton = () => {
    if(selectedBuilding !== ''){
      return(
        <button
          className='btn btn-building-select'
          onClick={handleOnContinueClick}
        >
          {(isLoading) ? 'Loading...' : 'Continue'}
        </button>
      );
    }

    return null;
  };

  return(
    <div className='building-select-container'>
      <div
        className='building-select-icon-row'
      >    
        <figure>
          <img className='telus-logo' src={logo} alt={getLocalizedString('telus_logo_aria')}/>
        </figure>
        <button
          className='language-toggle text-link'
          onClick={handleLanguageClick}
        >
          {(selectedLanguage === 'en') ? 'Français' : 'English'}
        </button>
      </div>
      <h6>TELUS Art Portal</h6>
      <h3>Building Select</h3>
      <p>Welcome to the TELUS Art App. This digital experience is aimed at providing a thorough and dynamic exploration of the extensive
TELUS art collection.</p>
      <label>
        Building
        <select
          className='building-select-input icon-chevron-down'
          onChange={handleOnSelectChange}
        >
          <option value=''>Select Location</option>
          {
            list.map((building) => {
              return(
                <option
                  key={building.blog_id}
                  value={building.path}
                >
                  {building.sitename}
                </option>
              );
            })
          }
        </select>
      </label>
      {checkButton()}
    </div>
  );
};

BuildingSelectPage.propTypes = {
  list: PropTypes.array.isRequired
};

export default BuildingSelectPage;