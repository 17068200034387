import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function Quote({quote, name}) {
  
  if (!quote) { 
    return; 
  } 
  return (    
    <motion.div
      initial={{
        opacity: 0,
        y: 50
      }}

      whileInView={{
        opacity: 1,
        y: 0
      }}

      transition={{
        duration: 1
      }}

      viewport={{ once: true }}
      
      className="quote-component container"
    >
      <h4>
        <span className="quote">
          <span className="quotation-mark">&quot;</span>
          {quote}
          <span className="quotation-mark">&quot;</span>
        </span>
      </h4>
      <p className={(name.length <= 0) ? '' : 'quote-name'}>{name}</p>
    </motion.div>
  );
}

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Quote;