import React, {useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import AppDataContext from '../../context/AppDataContext';

//components
import HeroImage from '../common/HeroImage';
import Quote from '../common/Quote';
import Text from '../Text';
import TourImageCTA from '../common/TourImageCTA';

const GuidedToursPage = () => {
  const {buildingId, tourId} = useParams();

  const {getTourById, setCurPage, getThemeLevelById, getLocalizedString} = useContext(AppDataContext);

  /**
   * -The overview page for the Guided Tours
   * -Note that the header has a solid black background
   * -Display a hero image
   * -Display the description of the guided tour
   * -Display a list of artwork for the guided tour
   */
  useEffect(()=>{
    const page = {
      type: 1,
      label: 'Guided Tour'
    };

    setCurPage(page);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  // Function to check if the level is already set in the ArtLocationDataObject
  const checkLevel = (artworkData) => {
    // If no level is set, find it using the utility function
    if(artworkData.level.length <= 0){
      const foundData = getThemeLevelById(artworkData.artIds);
      artworkData.level = foundData.level;
      artworkData.tourCtaData.themeSlug = foundData.theme;
      artworkData.tourCtaData.artLocationSlug = foundData.art;

      return foundData.level;
    }

    // If the level is already set, save on performance and return the level
    return artworkData.level;
  };

  const checkLink = (artworkData) => {
    if(artworkData.tourCtaData.themeSlug !== null){
      const theme = artworkData.tourCtaData.themeSlug;
      const artSlug = artworkData.tourCtaData.artLocationSlug;

      return '/' + buildingId + '/level/' + theme + '/art/' + artSlug;
    }

    return '/' + buildingId + '/collection/';
  };

  const buildPage = () => {
    //Get a reference to the desired data set for the page content
    const data = getTourById(tourId);

    return(
      <>
        <HeroImage imageUrl={data.imageUrl} altText={data.imageAlt} shouldAnimate={true} />
        <div className='tour-container'>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='level-title tag bronze-tag'>{'Tour'}</motion.div>
          {
            // Loop through and build out the different component types
            (data.content) && data.content.map((section, index) => {
              switch(section.acf_fc_layout){
                case 'text':
                  return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} />;
                case 'quote':
                  return <Quote key={section.acf_fc_layout + '-' + index} quote={section.quote} name={section.quotee} />;
                case 'message':
                  return <p key={section.acf_fc_layout + '-' + index}>Insert Message component here</p>;
              }
            })
          }
        </div>
        <div className='tour-artwork-container'>
          {
            (data.locations) && data.locations.map( (artwork, index) => {
              return(
                <TourImageCTA
                  key={artwork.slug}
                  link={checkLink(artwork)}
                  tag={getLocalizedString('label_level') + ' ' + checkLevel(artwork)}
                  locationIndex={index.toString()}
                  title={artwork.getArtArtist()}
                  subtitle={artwork.getArtLocationTitle()}
                  ctaLinkLabel={getLocalizedString('label_about_artwork')}
                  imageUrl={artwork.imageUrl}
                  aboutContent={artwork.content}
                  moreCTALabel={getLocalizedString('label_more_about_artwork')}
                />
              );
            })
          }
        </div>
        <div className='bottom-line'></div>
      </>
    );
  };

  return(
    <div className='tour-page'>
      {buildPage()}
    </div>
  );
};

export default GuidedToursPage;