import NoImage from '../assets/images/no-image.svg';

export function checkImage(url){
  return (url) ? url : NoImage;
}

// Utility function to convert the time in seconds in a readable string in the format MM:SS
export function parseTime(cur, dur){
  let time = dur - cur;
  if(isNaN(time)){
    return '00:00';
  }
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - (minutes * 60));

  let minutesVal = (minutes < 10) ? '0' + minutes : minutes;
  let secondsVal = (seconds < 10) ? '0' + seconds : seconds;
  return minutesVal + ':' + secondsVal;
}

// These strings are no longer used, these values are now loaded from the api
export const appStrings = {
  telus_logo_aria: {
    en: 'TELUS logo',
    fr: 'Logo TELUS'
  },
  header_back: {
    en: 'Back',
    fr: 'Dos'
  },
  header_aria_menu_open: {
    en: 'Open Menu',
    fr: 'Ouvrir le menu'
  },
  header_aria_menu_close: {
    en: 'Close menu',
    fr: 'Fermer le menu'
  },
  menu_art: {
    en: 'Art Collection',
    fr: 'Collection d\'art'
  },
  menu_tour: {
    en: 'Guided Tours',
    fr: 'Visites Guidées'
  },
  menu_about: {
    en: 'About the Collection',
    fr: 'À propos de la collection'
  },
  menu_privacy: {
    en: 'Privacy Policy',
    fr: 'Politique de Confidentialité'
  },
  audio_aria_back: {
    en: 'Rewind audio 5 seconds',
    fr: 'Rembobiner l\'audio de 5 secondes'
  },
  audio_aria_forward: {
    en: 'Fast forward audio 5 seconds',
    fr: 'Avance rapide audio 5 secondes'
  },
  audio_aria_play: {
    en: 'Play audio',
    fr: 'Lecture audio'
  },
  audio_aria_pause: {
    en: 'Pause audio',
    fr: 'Suspendre le son'
  },
  collection_level_desc: {
    en: 'Explore the TELUS Sky art collection and experience the story of ‘sky’ through our curatorial themes.',
    fr: 'Explorez la collection d\'œuvres d\'art TELUS Sky et découvrez l\'histoire du « ciel » à travers nos thèmes de conservation.'
  },
  collection_tours_sub_title: {
    en: 'Tours from the collection curators',
    fr: 'Visites des conservateurs de collections'
  },
  collection_about_title: {
    en: 'About the Collection',
    fr: 'À propos de la collection'
  },
  collection_about_sub_title: {
    en: 'Discover why TELUS collects art',
    fr: 'Découvrez pourquoi TELUS collectionne l\'art'
  },
  gallery_preview_open: {
    en: 'Open in Gallery',
    fr: 'Ouvrir dans la galerie'
  },
  label_about_artist: {
    en: 'About the Artist',
    fr: 'À propos de l\'artiste'
  },
  label_about_artwork: {
    en: 'About the Artwork',
    fr: 'À propos de l\'oeuvre'
  },
  label_about_work: {
    en: 'About the Work',
    fr: 'À propos du travail'
  },
  label_art_locations: {
    en: 'Art Locations',
    fr: 'Lieux d\'art'
  },
  label_artist: {
    en: 'Artist',
    fr: 'Artiste'
  },
  label_credit: {
    en: 'Credit',
    fr: 'Crédit'
  },
  label_date: {
    en: 'Date',
    fr: 'Date'
  },
  label_dimensions: {
    en: 'Dimensions',
    fr: 'Dimensions'
  },
  label_edition: {
    en: 'Edition',
    fr: 'Édition'
  },
  label_explore: {
    en: 'Explore',
    fr: 'Explorer'
  },
  label_guided_tours: {
    en: 'Guided Tours',
    fr: 'Visites guidées'
  },
  label_images: {
    en: 'Images',
    fr: 'Images'
  },
  label_learn_more: {
    en: 'Learn More',
    fr: 'Apprendre encore plus'
  },
  label_level: {
    en: 'Level',
    fr: 'Niveau'
  },
  label_levels: {
    en: 'Levels',
    fr: 'Les Niveaux'
  },
  label_level_overview: {
    en: 'Level Overview',
    fr: 'Aperçu du niveau'
  },
  label_map: {
    en: 'Map',
    fr: 'Carte'
  },
  label_medium: {
    en: 'Medium',
    fr: 'Moyen'
  },
  label_more_about_artwork: {
    en: 'More About the Artwork',
    fr: 'En savoir plus sur l\'œuvre'
  },
  label_next: {
    en: 'Next',
    fr: 'Suivant'
  },
  label_next_artwork: {
    en: 'Next Artwork',
    fr: 'Oeuvre suivante'
  },
  label_prev: {
    en: 'Prev',
    fr: 'Précédent'
  },
  label_prev_artwork: {
    en: 'Prev Artwork',
    fr: 'Oeuvre précédente'
  },
  label_tour: {
    en: 'Tour',
    fr: 'Visite'
  },
  label_title: {
    en: 'Title',
    fr: 'Titre'
  },
  label_welcome: {
    en: 'Welcome',
    fr: 'Bienvenu'
  },
};