import React, {useEffect, useState, useContext} from 'react';
import { useParams } from 'react-router-dom';

//Context
import AppDataContext from '../../context/AppDataContext';

const Map = () => {
  const {buildingId, levelId, tourId, artId} = useParams();

  const {hasDataLoaded, loadAppData, getLevelById, getTourById, getLocalizedString} = useContext(AppDataContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [artData, setArtData] = useState({});
  const [artList, setArtList] = useState([]);

  useEffect(()=> {
    if(!hasDataLoaded){
      //If data hasn't loaded, load it now
      loadAppData(buildingId);
    }else{
      parseData();
    }
  }, []);

  // Wait until data is loaded and set the local page state to render
  useEffect(()=>{
    if(hasDataLoaded){
      parseData();
      setIsLoaded(true);
    }
  },[hasDataLoaded]);

  const parseData = () =>{
    const data = (levelId !== undefined) ? getLevelById(levelId) : getTourById(tourId);

    const artData = data.locations.filter( obj => {
      return obj.slug.toString() === artId;
    })[0];

    const foundArt = {
      mapUrl: (data.mapUrl) ? data.mapUrl : '',
      level: data.themeLevel, // This value should be pulled from the art
      title: artData.getArtLocationTitle() // This is just here to use the variable
    };

    setArtData(foundArt);

    //Loop through the artwork and find all art on the same floor
    let foundArtList = [];
    data.locations.map( (art) => {
      const a = {
        artist: art.getArtArtist(),
        title: art.getArtLocationTitle(),
        id: art.slug.toString()
      };
      foundArtList.push(a);
    });

    setArtList(foundArtList);
  };

  const buildPage = () => {
    return(
      <div className='map-page'>
        <div className='map-container'>
          <div
            className='map-image'
            style={{
              backgroundImage: 'url('+ artData.mapUrl +')'
            }}
          >
          </div>
        </div>
        <div className='detail-header'>
          <div className="tag bronze-tag">{getLocalizedString('label_level') + ' ' + artData.level}</div>
        </div>
        <div className='detail-body'>
          {
            artList.map((item) => {
              let selected = '';
              if(item.id === artId){
                selected = 'selected';
              }

              return(
                <div key={item.id} className='row'>
                  <p className='col-2 art-id list-title'>{item.id}</p>
                  <div className={'col-10 art-info ' + selected}>
                    <p>{item.artist}</p>
                    <p>{item.title}</p>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="bottom-line"></div>
      </div>
    );
  };

  return(
    <>
      {(isLoaded) ? buildPage() : <p>Loading</p>}
    </>
  );
};

export default Map;