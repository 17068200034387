import React, {useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

//Context
import AppDataContext from '../../context/AppDataContext';

const TourGalleryPreview = ({artId, galleryId, imageUrl, imageAlt, numArt}) => {
  const {getLocalizedString} = useContext(AppDataContext);
  const {buildingId, levelId, tourId} = useParams();

  const checkLink = () => {
    let segment = '';
    if(levelId !== undefined){
      if(levelId.length > 0){
        segment = '/level/' + levelId;
      }
    }

    if(tourId !== undefined){
      if(tourId.length > 0){
        segment = '/guided-tour/' + tourId;
      }
    }

    return '/' + buildingId + segment + '/art/' + artId + '/gallery/' + galleryId;
  };

  return(
    <div className='tour-gallery-preview'>
      <div className='gallery-preview-image-container'>
        <img src={imageUrl} alt={imageAlt} />  
      </div>
      <div className="gallery-cta">
        <Link to={checkLink()}>
          <div className='link-container'>
            <span>{getLocalizedString('gallery_preview_open')}</span>
            <span className='chevron'></span>
          </div>
        </Link>
        <p className="images-counter">{numArt + ' ' + ((numArt <= 1) ? getLocalizedString('label_image') : getLocalizedString('label_images'))}</p>
      </div>
    </div>
  );
};

TourGalleryPreview.propTypes = {
  artId: PropTypes.string.isRequired,
  galleryId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  numArt: PropTypes.number.isRequired
};

export default TourGalleryPreview;