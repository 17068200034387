import React from 'react';
import { useParams } from 'react-router-dom';

import TelusSkyLogo from './TelusSkyLogo';

const HomeLogo = () => {
  const {buildingId} = useParams();
  
  return (
    <div className='home-logo'>
      {
        // Add custom building logos depending on the buildingId
        (buildingId === 'sky-calgary') && <TelusSkyLogo />
      }
    </div>
  );
};

export default HomeLogo;