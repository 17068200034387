import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ArtNavButton = ({link, direction, label}) => {

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  };

  return(
    <Link to={link}>
      <div className={['art-nav-button', (direction > 0) ? 'forward' : 'backward'].join(' ')} onClick={handleOnClick}>
        <div className={['arrow', (direction > 0) ? 'icon-chevron-right' : 'icon-chevron-left'].join(' ')}></div>
        <p>{label}</p>
      </div>
    </Link>
  );
};

ArtNavButton.propTypes = {
  link: PropTypes.string.isRequired,
  direction: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export default ArtNavButton;