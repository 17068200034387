
import React, {useContext} from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

//Context
import AppDataContext from '../../context/AppDataContext';

function GalleryPreview({artId, galleryId, imageUrl, imageAlt, numArt}) {
  const {getLocalizedString} = useContext(AppDataContext);
  const {buildingId, levelId, tourId} = useParams();

  const checkLink = () => {
    let segment = '';
    if(levelId !== undefined){
      if(levelId.length > 0){
        segment = '/level/' + levelId;
      }
    }

    if(tourId !== undefined){
      if(tourId.length > 0){
        segment = '/guided-tour/' + tourId;
      }
    }

    return '/' + buildingId + segment + '/art/' + artId + '/gallery/' + galleryId;
  };

  return (
    <>
      <div className="gallery-preview">
        <div className="image-container">
          <img src={imageUrl} alt={imageAlt} />  
        </div>
        <div className="gallery-cta">
          <p className="images-counter">{numArt + ' ' + ((numArt <= 1) ? getLocalizedString('label_image') : getLocalizedString('label_images'))}</p>
          <Link to={checkLink()}>{getLocalizedString('gallery_preview_open')}</Link>
        </div>
      </div>
    </>
  );
}

GalleryPreview.propTypes = {
  artId: PropTypes.string.isRequired,
  galleryId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  numArt: PropTypes.number.isRequired
};

export default GalleryPreview;