import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppDataContext from '../context/AppDataContext';

const ArtistInfo = ({ tag, artist, title, date, medium, edition, credit, mapLink }) => {
  const { getLocalizedString } = useContext(AppDataContext);

  return (
    <div
      className='artist-info'
    >
      <div className="tag bronze-tag">{tag}</div>
      <h1>{artist}</h1>
      <h2 className="art-title">{title}</h2>
      <div
        className='info-container container'
      >
        <div className='row'>
          <div
            className='col-3'
          >
            <p className='list-title'>{getLocalizedString('label_date')}</p>
          </div>
          <div
            className='col-9'
          >
            <p className='list-detail'>{date}</p>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-3'
          >
            <p className='list-title'>{getLocalizedString('label_medium')}</p>
          </div>
          <div
            className='col-9'
          >
            <p className='list-detail'>{medium}</p>
          </div>
        </div>
        {(edition.length > 0) &&
          <div className='row'>
            <div
              className='col-3'
            >
              <p className='list-title'>{getLocalizedString('label_edition')}</p>
            </div>
            <div
              className='col-9'
            >
              <p className='list-detail'>{edition}</p>
            </div>
          </div>
        }
        <div className='row'>
          <div
            className='col-3'
          >
            <p className='list-title'>{getLocalizedString('label_credit')}</p>
          </div>
          <div
            className='col-9'
          >
            <p className='list-detail'>{credit}</p>
          </div>
        </div>
        <div className='row map-button-row'>
          <Link to={mapLink}>
            <div className='map-button'>
              <span className='map-label'>{getLocalizedString('label_map')}</span>
              <div className='icon-chevron-right map-arrow'></div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

ArtistInfo.propTypes = {
  tag: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
  edition: PropTypes.string.isRequired,
  credit: PropTypes.string.isRequired,
  mapLink: PropTypes.string.isRequired
};

export default ArtistInfo;