class LoadedDataObject{
  #jsonContent = {};

  constructor(id){
    this.id = id;

    this.#jsonContent['en'] = [];
    this.#jsonContent['fr'] = [];
  }

  setJsonContent(value, locale){
    this.#jsonContent[locale] = value;
  }

  getJsonContent(locale){
    return this.#jsonContent[locale];
  }
}

export default LoadedDataObject;