import React, {useEffect, useContext} from 'react';
//Context
import AppDataContext from '../../context/AppDataContext';

import HeroImage from '../common/HeroImage';
import Text from '../Text';
import Quote from '../common/Quote';

import { checkImage } from '../../utils/Utils';

const AboutPage = () => {
  const { selectedLanguage, loadedAppData, setCurPage } = useContext(AppDataContext);

  /**
   * -The About page for the app
   * -Display a hero image
   * -The body of the content should be dynamically built using information from the REST API
   *  -loop / .map through the data and display the proper component
   *  -links needs to be available
   */
  useEffect(()=>{
    const page = {
      type: 1,
      label: 'About'
    };

    setCurPage(page);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const buildPage = () => {
    const data = loadedAppData.current['aboutPage'].getJsonContent(selectedLanguage);
    
    return(
      <>
        <HeroImage imageUrl={checkImage(data.acf.hero_image)} altText={'About Page hero image'} shouldAnimate={true} />
        {
          // Loop through and build out the different component types
          data.acf.content_sections.map((section, index) => {
            switch(section.acf_fc_layout){
              case 'text':
                return <Text page="about" key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} link={section.link} bgStyle="opt1"/>;
              case 'quote':
                return <Quote key={section.acf_fc_layout + '-' + index} quote={section.quote} name={section.quotee} />;
              case 'message':
                //TODO: this should also be a text component but with the styling of the 'Message from the CEO' section in about page
                return <p key={section.acf_fc_layout + '-' + index}>Insert Message component here</p>;
            }
          })
        }
      </>
    );
  };

  return(
    <>
      {buildPage()}
    </>
  );
};

export default AboutPage;