import React from 'react';
// import PropTypes from 'prop-types';
import { useEffect, useState, useRef, useContext } from 'react';

import AppDataContext from '../context/AppDataContext';

import { parseTime } from '../utils/Utils';

const Audio = () => {
  // Include the audio source and audio title from the AudioContext
  const { audioSrc, setAudioSrc, audioTitle, getLocalizedString } = useContext(AppDataContext);

  const [isPlaying, setIsPlaying] = useState(false); // If the audio player is playing an audio file
  const [curTime, setCurTime] = useState(0); // The current timestamp in seconds of the audio file
  const [duration, setDuration] = useState(0); // The total duration in seconds of the audio file
  const [isOpen, setIsOpen] = useState(false); // If the audio player is showing or not
  const [isTitleOverflow, setIsTitleOverflow] = useState(false); // If the audio title is longer than the container so it can animate

  const audioRef = useRef(null); // Ref to the audio clip in the DOM for controlling
  const timerRef = useRef(null); // Ref to the timer what checks the remaining time
  const titleRef = useRef(null); // Ref to the audio title to check if overflow
  const progressFillRef = useRef(null); // Ref to the audio progress bar

  useEffect(() => {
    //Ref may be null on unmount so store in a local instance
    const audioInstance = audioRef.current;
    // When the component is unmounted reset the audio player
    return () => {
      // Pause the audio
      audioInstance.pause();

      // Clear the audio source
      audioInstance.src = '';

      resetAudio();
      setIsOpen(false);
      setAudioSrc('');
    };
  }, []);

  // When a change in the audioSrc is detected, set it as the audio url and start the timer
  useEffect(() => {
    audioRef.current.src = audioSrc;

    startTimer();
  }, [audioSrc]);

  // When a change in the audioTitle is detected, check for overflow to start the animation
  useEffect(() => {
    const checkTitle = isOverflow(titleRef.current);
    setIsTitleOverflow(checkTitle);
  }, [audioTitle]);

  // When a change in isPlaying is detected, if the player is not already open then open it if the audio is playing
  useEffect(() => {
    if (!isOpen) {
      if (isPlaying) setIsOpen(true);
    }
  }, [isPlaying]);

  // Visually reset the audio player, end timers
  const resetAudio = () => {
    setIsPlaying(false);
    setCurTime(0);
    endTimer();
  };

  // Start a timer to check the audio progress
  const startTimer = () => {
    // End any timers currently running
    endTimer();

    // If the audio clip has finished, reset the audio player
    audioRef.current.onended = () => {
      progressFillRef.current.style.width = '100%';
      resetAudio();
    };

    // Start a timer
    timerRef.current = setInterval(() => {
      // Ensure there is a ref to the audio clip
      if (audioRef.current !== null) {
        // Calculate the completion in a percentage
        let per = (audioRef.current.currentTime / audioRef.current.duration) * 100;

        // A secondary check if the audio is finished
        if (per >= 100) {
          endTimer();
        }

        // Set the current time value for screen rendering
        setCurTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
      }
    }, 100);
  };

  // Stop any timers running
  const endTimer = () => {
    if (timerRef.current != null) {
      clearInterval(timerRef.current);
    }
  };

  // Listener for when an audio clip starts playing
  const handleOnPlay = () => {
    startTimer();
    setIsPlaying(true);
  };

  // Button handler for playing/pausing
  const handlePlayClick = () => {
    let curPlayState = isPlaying;

    if (curPlayState) {
      audioRef.current.pause();
      endTimer();
    } else {
      audioRef.current.play();
      startTimer();
    }

    setIsPlaying(!curPlayState);
  };

  // Button handler for going back 5 seconds in the audio clip
  const handleBackClick = () => {
    let newTime = audioRef.current.currentTime - 5;

    if (newTime < 0) newTime = 0;

    audioRef.current.currentTime = newTime;
    setCurTime(newTime);
  };

  // Button handler for going forward 5 seconds in the audio clip
  const handleForwardClick = () => {
    let newTime = audioRef.current.currentTime + 5;

    if (newTime > audioRef.current.duration) newTime = audioRef.current.duration;

    audioRef.current.currentTime = newTime;
    setCurTime(newTime);
  };

  // Event handler to close the audio player
  const handleOnClose = () => {
    // Pause the audio
    audioRef.current.pause();

    // Clear the audio source
    audioRef.current.src = '';

    resetAudio();
    setIsOpen(false);
    setAudioSrc('');
  };

  // Utility function to check if an element has an overflow
  const isOverflow = (element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  };

  return (
    <section
      className={['audio-container', (isOpen) ? 'opened' : ''].join(' ')}
    >
      <div
        key={audioSrc}
        className='audio-player'
      >
        <div
          className='audio-head'
          onClick={handleOnClose}
        >
          <div className='stroke'></div>
        </div>
        <div
          className='audio-body'
        >
          <div
            className='audio-info-container'
          >
            <p
              className={['audio-title', (isTitleOverflow) ? 'animated' : ''].join(' ')}
              ref={titleRef}
            >
              {audioTitle}
            </p>
            <p className='audio-time'>{parseTime(curTime, duration)} Remaining</p>
          </div>
          <div
            className='audio-controls-container'
          >
            <button
              className='audio-back icon-audio-back'
              aria-label={getLocalizedString('audio_aria_back')}
              onClick={handleBackClick}
            />
            <button
              className='audio-forward icon-audio-forward'
              aria-label={getLocalizedString('audio_aria_forward')}
              onClick={handleForwardClick}
            />
            <button
              className={['audio-control', (isPlaying) ? 'icon-audio-pause' : 'icon-audio-play'].join(' ')}
              aria-label={(isPlaying) ? getLocalizedString('audio_aria_pause') : getLocalizedString('audio_aria_play')}
              onClick={handlePlayClick}
            />
          </div>
        </div>
        <div
          className='audio-foot'
        >
          <div
            className='audio-progress-container'
          >
            <div
              className='audio-progress-fill'
              ref={progressFillRef}
              style={{
                width: ((curTime / duration) * 100) + '%'
              }}
            >
            </div>
          </div>
        </div>
        <audio
          className='audio-content'
          ref={audioRef}
          onPlay={handleOnPlay}
          autoPlay
        >
          <source src={audioSrc} type="audio/mpeg"></source>
          Your browser does not support the audio tag
        </audio>
      </div>
    </section>
  );
};

export default Audio;