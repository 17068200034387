import React from 'react';
import {motion} from 'framer-motion';

import TitleImgTelus from '../../assets/images/home-logo/telus-sky/title-telus.png';
import TitleImgSky from '../../assets/images/home-logo/telus-sky/title-sky.png';

const TelusSkyLogo = () => {
  return (
    <div className='telus-sky-container'>
      <div className='title-container'>
        <motion.img
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.1 }}
          className='title-img-telus'
          src={TitleImgTelus}
          alt='Telus' />
        <motion.img
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.4 }}
          className='title-img-sky'
          src={TitleImgSky}
          alt='Sky' />
      </div>
    </div>
  );
};

export default TelusSkyLogo;