import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link, useParams } from 'react-router-dom';
import AppDataContext from '../../context/AppDataContext';

const Menu = ({ isOpen, onNavClick }) => {
  const { buildingId } = useParams();

  const { selectedLanguage, setSelectedLanguage, getLocalizedString } = useContext(AppDataContext);

  const handleLangSelect = (e) => {
    setSelectedLanguage(e.currentTarget.innerHTML.toLowerCase());
  };

  return (
    <nav
      className={['menu-container', (isOpen) ? 'open' : ''].join(' ')}
    >
      <ul className='menu-items'>
        <li>
          <Link
            to={'/' + buildingId + '/collection'}
            className='menu-item'
            onClick={onNavClick}
          >
            {getLocalizedString('menu_art')}
          </Link>
        </li>
        <li>
          <Link
            to={'/' + buildingId + '/tour-overview'}
            className='menu-item'
            onClick={onNavClick}
          >
            {getLocalizedString('menu_tour')}
          </Link>
        </li>
        <li>
          <Link
            to={'/' + buildingId + '/about'}
            className='menu-item'
            onClick={onNavClick}
          >
            {getLocalizedString('menu_about')}
          </Link>
        </li>
      </ul>
      <div
        className='menu-foot'
      >
        <button
          className={['lang-button', (selectedLanguage === 'en') ? 'selected' : ''].join(' ')}
          onClick={handleLangSelect}
        >
          EN
        </button>
        <button
          className={['lang-button', (selectedLanguage === 'fr') ? 'selected' : ''].join(' ')}
          onClick={handleLangSelect}
        >
          FR
        </button>
        <Link
          to={'https://www.telus.com/' + 'en' + '/about/privacy'}
          className='menu-foot-link'
        >
          {getLocalizedString('menu_privacy')}
        </Link>
        <Link
          to={'https://www.telus.com/' + 'en'}
          className='menu-foot-link'
        >
          TELUS.com
        </Link>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onNavClick: PropTypes.func.isRequired
};

export default Menu;