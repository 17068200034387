import React from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

export function HighlightBg({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: '0px 0px -100px' });


  const textAnimation = {
    visible: { backgroundPosition: '-100% 0', transition: { duration: 2, ease: 'linear' } },
    hidden: { backgroundPosition: '0 0', transition: { duration: 0 } }
  };

  const control = useAnimation();

  useEffect(() => {
    if (isInView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, isInView]);

  return (
    <motion.span ref={ref} variants={textAnimation} initial="hidden" animate={control} className="highlighted-text" >
      {children}
    </motion.span>
  );
}

HighlightBg.propTypes = {
  children: PropTypes.element
};

export function ColourLetter({ children, highlightedTextLength, background }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: '0px 0px -100px' });
  const controlText = useAnimation();

  const backgroundColour = background === 'option 2' ? '#ffffff' : '#626262';

  const sentence = {
    hidden: { color: '#626262', transition: 0 },
    visible: {
      transition: {
        delay: 0.5,
        staggerChildren: 2 / highlightedTextLength,
      },
    },
  };

  useEffect(() => {
    if (isInView) {
      controlText.start('visible');
    } else {
      controlText.start('hidden');
    }
  }, [controlText, isInView]);

  const letter = {
    hidden: { color: backgroundColour },
    visible: {
      color: '#ffffff',

    }
  };
  return (
    <motion.span variants={sentence} initial="hidden" animate={controlText} ref={ref}>
      {
        children.split('').map((char, index) => {
          return (
            <motion.span key={char + '-' + index} variants={letter}>
              {char}
            </motion.span>
          );
        })
      }
    </motion.span>

  );
}

ColourLetter.propTypes = {
  children: PropTypes.string.isRequired,
  highlightedTextLength: PropTypes.number.isRequired,
  background: PropTypes.string
};
