import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import AppDataContext from '../../context/AppDataContext';

function HeroImage({ imageUrl, altText, shouldAnimate }) {
  const { prevButtonRect } = useContext(AppDataContext);
  const [isMounted, setIsMounted] = useState(false);

  const defaultStyle = {
    top: 72,
    left: 0,
    width: '100%',
    height: 448
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const checkState = () => {
    if (shouldAnimate) {
      if (isMounted) {
        return false;
      }

      return true;
    }

    return false;
  };

  const checkStyle = () => {
    if (shouldAnimate) {
      if (isMounted) {
        return defaultStyle;
      }

      if (prevButtonRect.current === null) {
        return defaultStyle;
      }

      return {
        top: prevButtonRect.current.top,
        left: prevButtonRect.current.left,
        width: prevButtonRect.current.width,
        height: prevButtonRect.current.height
      };
    }

    return defaultStyle;
  };

  return (
    <div className="hero-image">
      <motion.div
        layout
        transition={{ duration: 0.5 }}
        className={['image-container', (checkState()) ? 'animate' : ''].join(' ')}
        style={checkStyle()}
      >
        <img src={imageUrl} alt={altText} />
      </motion.div>
    </div>
  );
}

HeroImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  shouldAnimate: PropTypes.bool.isRequired
};

export default HeroImage;