import React, {useRef, useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import AppDataContext from '../../context/AppDataContext';

import {parseTime} from '../../utils/Utils';

const AudioClip = ({title, src}) => {
  const {setAudioSrc, setAudioTitle} = useContext(AppDataContext);
  const [audioDuration, setAudioDuration] = useState();

  const audioSrc = useRef(null);

  useEffect(()=>{
    // If the audio element is null create one
    if(audioSrc.current === null){
      audioSrc.current = document.createElement('audio');
      audioSrc.current.src = src;

      //Listen for when the meta data has loaded
      audioSrc.current.addEventListener('loadedmetadata', () =>{
        //Set the duration of the audio clip
        setAudioDuration(audioSrc.current.duration);
      });
    }
  }, []);

  //Set the audio title and source in the context once clicked
  const handleOnClick = () => {
    setAudioSrc(src);
    setAudioTitle(title);
  };

  return(
    <div
      className='audio-clip-container'
      onClick={handleOnClick}
    >
      <div
        className='audio-clip'
      >
        <div className='audio-clip-icon'>
          <button className={['icon-audio-play'].join(' ')} />
        </div>
        <div className='audio-clip-info'>
          <p className='audio-title'>{title}</p>
          <p className='audio-time'>{parseTime(0,audioDuration) + ' min'}</p>
        </div>
      </div>
    </div>
  );
};

AudioClip.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};

export default AudioClip;