import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';

import Text from '../Text';
import TourGalleryPreview from '../Gallery/TourGalleryPreview';

const TourImageCTA = ({link, tag, locationIndex, title, subtitle, ctaLinkLabel, imageUrl, aboutContent, moreCTALabel}) => {
  const [opened, setOpened] = useState(false);

  const handleAboutClick = () => {
    setOpened(!opened);
  };

  return(
    <div
      className='image-cta'
    >
      <motion.div
        layoutId={`image-container-${imageUrl}`}
        transition={{ duration: 0.5 }}
        className='image-container'
      >
        <img src={imageUrl} alt='need alt text' />
      </motion.div>
      <motion.h2
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        {title}
      </motion.h2>
      {(subtitle && subtitle.length > 0) && <motion.h4
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >{subtitle}</motion.h4>}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className='button-foot'>
        <div className="tag bronze-tag">{tag}</div>
        <span
          className={['text-link', 'cta-link', 'tour-cta-link', opened ? 'opened' : ''].join(' ')}
          onClick={handleAboutClick}
        >
          {ctaLinkLabel}
        </span>
      </motion.div>
      <div
        className={['tour-art-about-container', opened ? 'opened' : ''].join(' ')}
      >
        <div className='tour-art-about'>
          {
            // Loop through and build out the different component types
            (aboutContent) && aboutContent.map((section, index) => {
              switch(section.acf_fc_layout){
                case 'text':
                  return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} />;
                case 'gallery':
                  return <TourGalleryPreview
                    key={section.acf_fc_layout + '-' + index}
                    artId={locationIndex}
                    galleryId={index.toString()}
                    imageUrl={section.gallery[0]}
                    imageAlt={section.gallery[0]}
                    numArt={section.gallery.length}
                  />;
              }
            })
          }
          <Link to={link}>
            <div className='tour-art-more-cta'>
              <span>{moreCTALabel}</span><span className='chevron'></span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

TourImageCTA.propTypes = {
  link: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  locationIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  ctaLinkLabel: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  aboutContent: PropTypes.array.isRequired,
  moreCTALabel: PropTypes.string.isRequired
};

export default TourImageCTA;