import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import AppDataContext from '../../context/AppDataContext';

import Menu from './Menu';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { buildingId, levelId, tourId, artId, galleryId } = useParams();

  const { curPage, pageScrollY, getLocalizedString } = useContext(AppDataContext);

  const [hasBackground, setHasBackground] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(false);
  const [buildingName, setBuildingName] = useState('');

  /**
   * Click handler for when the back button is clicked
   */
  const handleBackClick = () => {
    const pathParts = location.pathname.split('/');

    let destination = '/' + buildingId + '/collection';

    if (pathParts[pathParts.length - 1] === 'map') {
      if (levelId !== undefined) {
        destination = '/' + buildingId + '/level/' + levelId + '/art/' + artId;
      } else {
        destination = '/' + buildingId + '/guided-tour/' + tourId + '/art/' + artId;
      }
    } else if (galleryId !== undefined) {
      //User is in a gallery, go back to the artwork

      if (levelId !== undefined) {
        destination = '/' + buildingId + '/level/' + levelId + '/art/' + artId;
      } else {
        destination = '/' + buildingId + '/guided-tour/' + tourId;
      }
    } else {
      if (artId !== undefined) {
        //If an artId exists, check to see if it's from a level or tour
        if (levelId !== undefined) {
          destination = '/' + buildingId + '/level/' + levelId;
        } else {
          destination = '/' + buildingId + '/guided-tour/' + tourId;
        }
      } else {
        //If no artId exists, check to see if they are on a guided tour
        if (tourId !== undefined) {
          //If a tourId exists navigate to the tour overview page
          destination = '/' + buildingId + '/tour-overview';
        }
      }
    }

    navigate(destination);
  };

  /**
   * Click handler for the menu button
   */
  const handleMenuClick = () => {
    const isOpen = !isMenuOpen;
    setIsMenuOpen(isOpen);

    // This is ia special case for the collections page where when the menu is closed and opened it needs to know if it has to animate the background due to the initial transparent background
    if (curPage !== null) {
      if (curPage.type === 0) {
        if (!scrolledDown) {
          setHasBackground(isOpen);
        }
      }
    }
  };

  /**
   * General click handler to close the menu when a menu item has been selected
   */
  const handleOnNavClick = () => {
    setIsMenuOpen(false);
  };

  /**
   * Listen for when the curPage state object in AppData context has changed
   */
  useEffect(() => {
    if (curPage !== null) {
      if (curPage.type === 0) {
        // Special case for the collections page
        setBuildingName(curPage.label);
        setHasBackground(false);
      } else {
        setHasBackground(true);
      }
    }


  }, [curPage]);

  /**
   * Listen for page scroll changes from the AppData context
   */
  useEffect(() => {
    if (curPage !== null) {
      if (curPage.type === 0) {
        // Special case for collections page that needs to animate to a solid background after scrolling down
        if (pageScrollY > 5) {
          setScrolledDown(true);
          if (!isMenuOpen) {
            setHasBackground(true);
          }
        } else {
          setScrolledDown(false);
          if (!isMenuOpen) {
            setHasBackground(false);
          }
        }
      }
    }
  }, [pageScrollY]);

  return (
    <>
      <header
        className={['header', (hasBackground) ? 'has-background' : ''].join(' ')}
      >
        <div className='header-lead'>
          <button
            className={['header-lead-item', 'back-button', (curPage !== null && curPage.type === 0) ? 'hidden' : ''].join(' ')}
            aria-label={getLocalizedString('header_back')}
            onClick={handleBackClick}
          >
            {getLocalizedString('header_back')}
          </button>
          <span
            className={['header-lead-item', 'building-title', (curPage !== null && curPage.type === 0) ? '' : 'hidden'].join(' ')}
          >
            {buildingName}
          </span>
        </div>
        <button
          className={['menu-button'].join(' ')}
          aria-label={(isMenuOpen) ? getLocalizedString('header_aria_menu_close') : getLocalizedString('header_aria_menu_open')}
          onClick={handleMenuClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
            <path
              className={['svg-menu', 'svg-graphic', (isMenuOpen) ? '' : 'visible'].join(' ')}
              d="M3,5c0-0.55,0.45-1,1-1h16c0.55,0,1,0.45,1,1s-0.45,1-1,1H4C3.45,6,3,5.55,3,5z M3,12c0-0.55,0.45-1,1-1h16 c0.55,0,1,0.45,1,1s-0.45,1-1,1H4C3.45,13,3,12.55,3,12z M4,18c-0.55,0-1,0.45-1,1s0.45,1,1,1h16c0.55,0,1-0.45,1-1s-0.45-1-1-1H4z"
            />
            <path
              className={['svg-close', 'svg-graphic', (isMenuOpen) ? 'visible' : ''].join(' ')}
              d="M13.4,12l6.8-6.8c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.2,3.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4 l6.8,6.8l-6.8,6.8c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l6.8-6.8l6.8,6.8c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L13.4,12z" />
          </svg>
        </button>
      </header>
      <Menu
        isOpen={isMenuOpen}
        onNavClick={handleOnNavClick}
      />
    </>
  );
};

export default Header;