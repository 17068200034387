import React, {useContext, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {motion} from 'framer-motion';

import AppDataContext from '../../context/AppDataContext';

import HeroImage from '../common/HeroImage';
import Quote from '../common/Quote';
import Text from '../Text';
import ImageCTA from '../common/ImageCTA';

import { checkImage } from '../../utils/Utils';

const LevelOverviewPage = () => {
  const {buildingId, levelId} = useParams();
  const {getLevelById, setCurPage, getLocalizedString} = useContext(AppDataContext);

  /**
   * -The overview page for the Level / Theme
   * -Note that the header has a solid black background
   * -Display a hero image
   * -Display the description of the theme for the level
   * -Display a list of artwork found on this level
   * -Note that the layout and functionality of this page is identical to the TourOverviewPage, probably should combine these 2 components and differentiate them with a prop
   */
  useEffect(()=>{
    const page = {
      type: 1,
      label: levelId
    };

    setCurPage(page);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  const buildPage = () => {
    //Get a reference to the desired data set for the page content
    const data = getLevelById(levelId);
    
    return(
      <>
        <HeroImage imageUrl={checkImage(data.imageUrl)} altText={data.imageAlt} shouldAnimate={true}/>
        <div className='level-overview-container'>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className='level-title tag bronze-tag'>{getLocalizedString('label_level') + ' ' + data.themeLevel}</motion.div>
          {
            // Loop through and build out the different component types
            (data.content) && data.content.map((section, index) => {
              switch(section.acf_fc_layout){
                case 'text':
                  return <Text key={section.acf_fc_layout + '-' + index} heading={section.heading} content={section.content} />;
                case 'quote':
                  return <Quote key={section.acf_fc_layout + '-' + index} quote={section.quote} name={section.quotee} />;
                case 'message':
                  return <p key={section.acf_fc_layout + '-' + index}>Insert Message component here</p>;
              }
            })
          }
        </div>
        <div className='level-artwork-container'>
          {
            (data.locations) && data.locations.map( (artLocation) => {
              return(
                <ImageCTA
                  key={artLocation.slug}
                  link={'/' + buildingId + '/level/' + data.slug.toLowerCase() + '/art/' + artLocation.slug}
                  tag={getLocalizedString('label_level') + ' ' + data.themeLevel}
                  title={artLocation.getArtArtist()}
                  subtitle={artLocation.getArtLocationTitle()}
                  ctaLinkLabel={getLocalizedString('label_learn_more')}
                  imageUrl={checkImage(artLocation.imageUrl)}
                />
              );
            })
          }
        </div>
        <div className='bottom-line'></div>
      </>
    );
  };

  return(
    <div className='level-overview-page'>
      {buildPage()}
    </div>
  );
};

export default LevelOverviewPage;