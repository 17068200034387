/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HighlightBg, ColourLetter } from './common/HighlightText';
import PropTypes from 'prop-types';

function Text({ heading, content, page, link, bgStyle }) {

  //State
  const [textData, setTextData] = useState([]);

  const location = useLocation();
  const { buildingId } = useParams();

  const loadTextData = () => {

    // change html tags and convert the string into an array
    let newStr = content.replaceAll('<p>', '+++');
    newStr = newStr.replaceAll('</p>', '+++');
    let escapeEnt = '\n';
    const dataArray = newStr.split('+++').filter(n => {
      if (n !== escapeEnt) {
        return n;
      }
    });

    //Detect what element of the array has <span>
    function getHighlightedText(str, char1, char2) {
      return str.substring(
        str.indexOf(char1),
        str.lastIndexOf(char2)
      );
    }

    const getSpan = dataArray.map(el => {
      if (el.includes('<mark class="highlight">')) {

        let highlight = getHighlightedText(el, '<mark class="highlight">', '</mark>');

        return highlight.replace('<mark class="highlight">', '');
      }

    });

    const contentArray = dataArray.map(el => {
      let newStr = el.replace('<mark class="highlight">', ' ');
      newStr = newStr.replace('</mark>', '');

      return newStr;
    });

    const textResult = contentArray.map((par, index) => {

      if (par.includes(getSpan[index]) && getSpan[index] !== undefined) {
        const highlightedTextLength = getSpan[index].length;
        const startString = par.indexOf(getSpan[index]);
        const initialContent = par.substring(0, startString - 1);
        const startLastPart = initialContent.length + highlightedTextLength;
        const endContent = par.substring(startLastPart + 1, par.length);
        return <p key={index}><span dangerouslySetInnerHTML={{ __html: initialContent }}></span> <HighlightBg><ColourLetter highlightedTextLength={highlightedTextLength}>{getSpan[index]}</ColourLetter></HighlightBg> <span dangerouslySetInnerHTML={{ __html: endContent }}></span></p>;
      } else {
        return <p key={index} dangerouslySetInnerHTML={{ __html: par }}></p>;
      }

    });

    setTextData(textResult);

  };

  useEffect(() => {
    if (content) {
      loadTextData();
    }
  }, [content]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className={`container text-component ${bgStyle ? bgStyle : ''}`}
    >
      {
        page === 'about' ? (
          <>
            <h5 className="heading-about">{heading}</h5>
            {textData}
            {link && <a className="text-link" href={link.url}>{link.title}</a>}
          </>
        ) : (
          <>
            <h1 className='heading'>{heading}</h1>
            {textData}
          </>
        )
      }

    </motion.div>
  );
}

Text.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  page: PropTypes.string,
  link: PropTypes.string,
  bgStyle: PropTypes.string,
};

export default Text;